import React from "react"

import "../css/pages/mentions-legales.scss"
import Layout from "../components/Layout/layout"
import SEO from "../components/seo"
import Section from "../components/section"
import {Link} from "gatsby";

const MentionsLegales = () => (
  <Layout>
    <SEO title="Mentions légales" />
    <div className={"page mentions-legales"}>
        <div className={"header"}>
            <h1 className={"title"}>Mentions légales</h1>
        </div>
      <Section title={"Propriétaire du site"}>
          <p>
              Athies Batiment<br/>
              SIRET : 83977760400019<br/>
              Adresse : 76 Rue du Château d'Eau, 02840 Athies-sous-Laon, France<br/>
              Téléphone : 06.46.16.87.23<br/>
              Adresse mail : jeanbaptiste.prudhomme@sfr.fr<br/>
          </p>
      </Section>

      <Section title={"Editeur du site"}>
          <p>
              Le site web a été réalisé par l'agence SR Digitale.<br/>
              SIRET : 84004944900035<br/>
              Adresse : 27 rempart du Nord, 02870 Crépy, France<br/>
              Téléphone : <a href="tel:+33689987867" title="Appeler l'agence SR Digitale">06.89.98.78.67</a><br/>
              Adresse mail : <a href="mailto:contact@dimitri-dumont.fr" title="Envoyer un mail à SR Digitale">contact@srdigitale.fr</a><br/>
              Site web : <a href="https://www.srdigitale.fr" title="Site de l'agence SR Digitale">www.srdigitale.fr</a>
          </p>
      </Section>

      <Section title={"Hébergement"}>
          <p>
              Le site est hébergé par OVH.<br/>
              SAS au capital de 10 069 020 €<br/>
              RCS Lille Métropole 424 761 419 00045<br/>
              Code APE 2620Z<br/>
              N° TVA : FR 22 424 761 419<br/>
              Siège social : 2 rue Kellermann - 59100 Roubaix - France
          </p>
      </Section>

      <Section title={"Gestion des données personnelles"}>
          <>
              <p>
                  En France, les données personnelles sont notamment protégées par la loi n° 78-87 du 6 janvier 1978, la loi n° 2004-801 du 6 août 2004, l'article L. 226-13 du Code pénal et la Directive Européenne du 24 octobre 1995.
              </p>
              <p>
                  A l'occasion de l'utilisation du site www.athies-batiment.fr, peuvent êtres recueillies : l'URL des liens par l'intermédiaire desquels l'utilisateur a accédé au site www.athies-batiment.fr, le fournisseur d'accès de l'utilisateur, l'adresse de protocole Internet (IP) de l'utilisateur.
              </p>
              <p>
                  En tout état de cause l'entreprise Athies Batiment ne collecte des informations personnelles relatives à l'utilisateur que pour le besoin de certains services proposés par le site www.athies-batiment.fr. L'utilisateur fournit ces informations en toute connaissance de cause, notamment lorsqu'il procède par lui-même à leur saisie. Il est alors précisé à l'utilisateur du site www.athies-batiment.fr l'obligation ou non de fournir ces informations.
              </p>
              <p>
                  Conformément aux dispositions des articles 38 et suivants de la loi 78-17 du 6 janvier 1978 relative à l'informatique, aux fichiers et aux libertés, tout utilisateur dispose d'un droit d'accès, de rectification et d'opposition aux données personnelles le concernant, en effectuant sa demande écrite et signée, accompagnée d'une copie du titre d'identité avec signature du titulaire de la pièce, en précisant l'adresse à laquelle la réponse doit être envoyée.
              </p>
              <p>
                  Aucune information personnelle de l'utilisateur du site www.athies-batiment.fr n'est publiée à l'insu de l'utilisateur, échangée, transférée, cédée ou vendue sur un support quelconque à des tiers.
              </p>
              <p>
                  Le site n'est pas déclaré à la CNIL car il ne recueille pas d'informations personnelles.
              </p>
              <p>
                  Les bases de données sont protégées par les dispositions de la loi du 1er juillet 1998 transposant la directive 96/9 du 11 mars 1996 relative à la protection juridique des bases de données.
              </p>
          </>
      </Section>

      <Section title={"Liens hypertextes et cookies"}>
          <>
              <p>
                  Le site www.athies-batiment.fr contient un certain nombre de liens hypertextes vers d'autres sites, mis en place avec l'autorisation de l'entreprise de M. Dimitri Dumont. Cependant, l'entreprise Athies Batiment n'a pas la possibilité de vérifier le contenu des sites ainsi visités, et n'assumera en conséquence aucune responsabilité de ce fait.
              </p>
              <p>
                  La navigation sur le site www.athies-batiment.fr est susceptible de provoquer l'installation de cookie(s) sur l'ordinateur de l'utilisateur. Un cookie est un fichier de petite taille, qui ne permet pas l'identification de l'utilisateur, mais qui enregistre des informations relatives à la navigation d'un ordinateur sur un site. Les données ainsi obtenues visent à faciliter la navigation ultérieure sur le site, et ont également vocation à permettre diverses mesures de fréquentation.
              </p>
              <p>
                  Le refus d'installation d'un cookie peut entraîner l'impossibilité d'accéder à certains services. L'utilisateur peut toutefois configurer son ordinateur de la manière suivante, pour refuser l'installation des cookies : Sous Internet Explorer : onglet outil / options internet. Cliquez sur Confidentialité et choisissez Bloquer tous les cookies. Validez sur Ok. Sous Netscape : onglet édition / préférences. Cliquez sur Avancées et choisissez Désactiver les cookies. Validez sur Ok.
              </p>

              <p>Pour en savoir plus sur notre politique de confidentialité, <Link to={"/politique-de-confidentialite"}>cliquez-ici</Link></p>
          </>
      </Section>
    </div>
  </Layout>
)

export default MentionsLegales
